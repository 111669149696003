import * as utilCommon from 'helpers/util-common';
import { OfficerTitleEnum } from 'models/common.models';
import { cacheManager, DropdownFormatting, getMessage } from '../../../../helpers/util-common';
import { primaryActivityModelConstant } from '../../../admin/manual-order/manual-order.model';
import { find, forEach, isEmpty, uniq } from 'lodash';

let self = {};
let IsProducer = false;
let IsExists = false;
let formattedCommittee = '';
let checkValue = 0;
let updatedDetailsForupdateMemberCommitteeBasicDetails = {};
let submitPayloads = null;
let primaryDetails = null;
let voteAndClassificationValue = 5;
let studentMembershipId = 17;
let officerTitleValue = 0;
let voteStatusValue = "false";

export const setClassInstance = (instance) => {
    self = instance;
    self.props.change('dateJoined', self.state.dateJoined)
    self.props.change('classification', self.state.classificationSelectedValue)
    self.props.change('voteStatus', self.state.voteStatus)
    self.props.change('voteDate', self.state.voteDate)
    self.props.change('officerTitle', self.state.officerTitleSelectedValue)
    self.props.change('noVoteReason', self.state.noVoteReasonSelectedValue)
    self.props.change('officerAppointedDate', self.state.officerAppointedDate)
    self.props.change('ClassificationDate', self.state.classificationAssignDate);
    self.props.change('voteDate', self.state.voteDate)
    const isParentCommittee = self.props.initialValues.LevelId === 1;
    if (isParentCommittee) {
        self.setState({
            isPrimaryActivityApplicable: true
        })
    }
    else {
        self.setState({
            isSaveButtonDisableForSubAndSectionCommitteePage: true
        })
    }
}

export const handleChangeDateJoined = (event, value) => {
    self.setState({ dateJoined: value })
    if (utilCommon.compareDates(utilCommon.formatDateToMMDDYYYY(value), utilCommon.formatDateToMMDDYYYY(self.state.classificationAssignDate), true, 'MM/DD/YYYY')) {
        self.props.change('ClassificationDate', value);
        self.setState({ classificationAssignDate: value })
    }
    if (utilCommon.compareDates(utilCommon.formatDateToMMDDYYYY(value), utilCommon.formatDateToMMDDYYYY(self.state.voteDate), true, 'MM/DD/YYYY')) {
        self.props.change('voteDate', value);
        self.setState({ voteDate: value })
    }
}
export const handleChangeVoteDate = (event, value) => {
    self.setState({ voteDate: value })
}
export const handleChangeClassification = (event, value) => {
    self.setState({ classificationSelectedValue: value })
}

export const handleChangeVoteStatus = (event, value) => {
    let voteStatus = event.currentTarget.querySelector('input').value;
    self.setState({ voteStatus })
}
export const handleChangeOfficerAppointedDate = (event, value) => {
    self.setState({ officerAppointedDate: value })
}
export const handleChangeNoVoteReason = (event, value) => {
    self.setState({ noVoteReasonSelectedValue: value })
}

export const handleChangeClassificationDate = (event, value) => {
    self.setState({ classificationAssignDate: value })
}
export const handleChangeUpdateReason = (event, value) => {
    self.setState({ UpdateReason: value })
}

export const handleSearh = (value) => {
    const regExp = new RegExp(/[`~!#$%^&*()|+\=?;:'",<>\{\}\[\]\\\/]/gi);
    self.setState({ regExpSpecialCharacter: regExp.test(value) });
    clearTimeout(self.searchAfterStopTypingTimer);

    if (value === ' ') {
        self.setState({ searchValue: value.trim(), searchResultsData: [] })
    } else {
        self.setState({ searchValue: value, searchResultsData: [] })
    }

    if (value.trim() !== '' && value.trim() !== null) {
        self.setState({ isLoading: true, showNoResults: false, isShowStudentProgramDisabled: false, searchResultsData: []})
        self.searchAfterStopTypingTimer = setTimeout(() => { searchTriggerChange(value) }, 1500);
    } else {
        self.setState({ isLoading: false, showNoResults: false, isShowStudentProgramDisabled: false, searchResultsData: [] })
    }
}
const searchTriggerChange = (value) => {
    if (!self.state.regExpSpecialCharacter) {
        self.setState({ isLoading: true, showNoResults: false, isShowStudentProgramDisabled: false, searchResultsData: [] })
        let searchValue = '';
        if (value.length == 1 && value === ".") {
            searchValue = value.replace(".", "@");
        } else {
            searchValue = (value.includes(".")) ? value.replace(".", "$") : value;// for GFN-4729 replacing for creating valid url
        }
        self.props.getSearchCommitteeRosterMember({ searchKey: searchValue, committeeId: self.props.initialValues.CommitteeId }, searchCommitteeRosterMember)
    } else {
        self.setState({ isLoading: false, showNoResults: true, searchResultsData: [] })
    }
}

const filterCommitteeRosterForStudentCommittee = (res, isLoader) => {
if (res && res.length > 0) {
    if (self.props.initialValues && self.props.initialValues.IsStudentEnabled) {
        self.setState({ isLoading: isLoader, searchResultsData: res })
    } else {
        const filterStudentMemberFromResponse = res.filter((item) => (item.MembershipTypeId !== studentMembershipId));
        if (filterStudentMemberFromResponse && filterStudentMemberFromResponse.length > 0) {
            self.setState({ isLoading: isLoader, searchResultsData: filterStudentMemberFromResponse })
        } else {
            self.setState({ isLoading: isLoader, showNoResults: true, isShowStudentProgramDisabled: true, searchResultsData: [] })
        }
    }
} else {
    self.setState({ isLoading: isLoader, showNoResults: true, searchResultsData: [] });
}
}

const searchCommitteeRosterMember = (res, isLoader) => {
    if (self.state.flagFeature && self.state.flagFeature.StudentCommitteeManagement) {
        filterCommitteeRosterForStudentCommittee(res, isLoader);
    } else {
        if (res && res.length > 0) {
            self.setState({ isLoading: isLoader, searchResultsData: res })
        } else {
            self.setState({ isLoading: isLoader, showNoResults: true, searchResultsData: [] })
        }
    }
}

const setFieldValuesForStudentMember = () => {
    self.setState({
        dateJoined: utilCommon.formatDateToMMDDYYYY(),
        classificationSelectedValue: voteAndClassificationValue,
        classificationAssignDate: utilCommon.formatDateToMMDDYYYY(),
        voteStatus: voteStatusValue,
        noVoteReasonSelectedValue: voteAndClassificationValue,
        voteDate: utilCommon.formatDateToMMDDYYYY(),
        officerTitleSelectedValue: officerTitleValue
    }, () => {
        self.props.change('dateJoined', self.state.voteStatus);
        self.props.change('classification', self.state.classificationSelectedValue);
        self.props.change('ClassificationDate', self.state.classificationAssignDate);
        self.props.change('voteStatus', self.state.voteStatus);
        self.props.change('noVoteReason', self.state.noVoteReasonSelectedValue);
        self.props.change('voteDate', self.state.voteDate);
        self.props.change('officerTitle', self.state.officerTitleSelectedValue);
    })
}

export const handleResultSelect = (value) => {
    const { isPrimaryActivityApplicable } = self.state;
    self.props.membershipTypeList.map((item) => {
        if (item.value === value.MembershipTypeId) {
            value.membershipTypeName = item.text
        }
    });
    // Call if Parent Commitee available in the page.
    if (isPrimaryActivityApplicable) {
        setPrimaryActivityList(value.MembershipTypeId);
    }
    // Call API if user add member to Sub/Section committee and member not exist in main committee
    if (self.props.initialValues.LevelId > 1) {
        let model = { committeeId: self.props.initialValues.CommitteeId, memberId: value.MemberId }
        self.props.checkMemberExistsInParentCommittee(model, (response) => {
            self.setState({ isPrimaryActivityApplicable: !response.IsExists });
            // If member is already added in Member Committee then no need to load primary activities
            if (!response.IsExists) {
                // Call if Parent Commitee available in the page.
                setPrimaryActivityList(value.MembershipTypeId);
            }
            else {
                self.setState({ isSaveButtonDisableForSubAndSectionCommitteePage: false });
            }
        })
    }
    // Check Any Org Associated with.
    if (value.FacilityName) {
        self.setState({ renderSelectedValueFromSearchList: value, isOrgAssociated: true })
    } else {
        self.setState({ renderSelectedValueFromSearchList: value, isOrgAssociated: false })
    }

    //Check if StudentCommitteeManagement feature flag is enabled and selected field is student member
    if (self.state.flagFeature.StudentCommitteeManagement && value.MembershipTypeId === studentMembershipId) {
        setFieldValuesForStudentMember();
        self.setState({ disableFieldsForStudentMember: true });
    } else {
        self.setState({
            natureOfInterest: '',
            disableFieldsForStudentMember: false
        }, () => {
            self.props.change('natureOfInterest', self.state.natureOfInterest);
        })
    }
}
export const setDropDowns = () => {
    self.props.getCommitteeClassificationTypeAndNoVoteReason(self.props.initialValues.CommitteeId, setDropDownData);
}

const setPrimaryActivityList = (memberTypeId) => {
    // If Membership type is REP.
    if (memberTypeId == 14) {
        memberTypeId = 3;
    }
    getPrimaryActivityList(memberTypeId || 0);
}

const setDropDownData = (classificationList, noVoteReasonList, officerTitleList) => {
    // For Binding DDL officerTitle
    if (officerTitleList) {
        let officerTitle = [{ 'key': 0, 'text': 'Select', 'value': 0 }];
        officerTitleList.map(item => {
            officerTitle.push({ key: item.OfficerTitleId, value: item.OfficerTitleId, text: item.OfficerTitleName });
        });
        self.setState({ officerTitle: officerTitle, officerTitleSelectedValue: 0 });
    }

    // For Binding DDL classification
    if (classificationList) {
        let classification = [];
        classificationList.map(item => {
            classification.push({ key: item.ClassificationTypeId, value: item.ClassificationTypeId, text: item.Name });
        });
        classification.unshift({ 'key': 0, 'text': 'Select', 'value': 0 });
        self.setState({ classification: classification, classificationSelectedValue: 0 });
    }

    // For Binding DDL noVoteReason
    if (noVoteReasonList) {
        let noVoteReason = [];
        noVoteReasonList.map(item => {
            noVoteReason.push({ key: item.VoteId, value: item.VoteId, text: item.Description });
        });
        noVoteReason.unshift({ 'key': 0, 'text': 'Select', 'value': 0 });
        self.setState({ noVoteReason: noVoteReason, noVoteReasonSelectedValue: 1 }, () => self.props.change('noVoteReason', self.state.noVoteReasonSelectedValue));
    }
}
export const validateHandler = (values, props) => {
    const errors = {};

    if (!values.dateJoined) {
        errors.dateJoined = utilCommon.getMessage(props.messageCodes, '3076.text');
    }
    if (values.classification == 0 && !values.classification) {
        errors.classification = utilCommon.getMessage(props.messageCodes, '8126.text');
    }
    if (values.voteStatus && !values.voteDate) {
        errors.voteDate = utilCommon.getMessage(props.messageCodes, '3080.text');
    } else if (values.voteStatus == 'false' && (values.noVoteReason == 0 && !values.noVoteReason)) {
        errors.noVoteReason = utilCommon.getMessage(props.messageCodes, '8127.text');
    }
    if (values.officerTitle > 0 && !values.officerAppointedDate) {
        errors.officerAppointedDate = utilCommon.getMessage(props.messageCodes, '3078.text');
    } else if (values.officerTitle > 0 && (values.officerAppointedDate.length < 7 || !utilCommon.checkIfDateValid(values.officerAppointedDate, 'MM/YYYY'))) {
        errors.officerAppointedDate = utilCommon.getMessage(props.messageCodes, '3079.text');
    } else if (values.officerTitle > 0 && values.officerAppointedDate) {
        let year = Number(values.officerAppointedDate.substring(3));
        if (isNaN(year) || (year <= 0)) {
            errors.officerAppointedDate = utilCommon.getMessage(props.messageCodes, '4003.text');
        } else if (utilCommon.compareDates(values.officerAppointedDate, new Date(), true, 'MM/YYYY')) {
            errors.officerAppointedDate = utilCommon.getMessage(props.messageCodes, '3084.text');
        } else if (utilCommon.compareDates(utilCommon.formatDateToMMYYYY(utilCommon.MinDate), values.officerAppointedDate, true, 'MM/YYYY')) {
            errors.officerAppointedDate = utilCommon.getMessage(props.messageCodes, '4021.text');
        }
    }
    if (!values.voteDate) {
        errors.voteDate = utilCommon.getMessage(props.messageCodes, '3080.text');
    } else if (values.voteDate && values.dateJoined && utilCommon.compareDates(values.dateJoined, values.voteDate, true)) {
        errors.voteDate = utilCommon.getMessage(props.messageCodes, '3114.text');
    }

    if (!values.ClassificationDate) {
        errors.ClassificationDate = utilCommon.getMessage(props.messageCodes, '3115.text');
    } else if (values.ClassificationDate && values.dateJoined && utilCommon.compareDates(values.dateJoined, values.ClassificationDate, true)) {
        errors.ClassificationDate = utilCommon.getMessage(props.messageCodes, '3113.text');
    }
    if (!values.UpdateReason) {
        errors.UpdateReason = utilCommon.getMessage(props.messageCodes, '8121.text')
    }

    // Primary Activity Errors Fields.
    if (values.primaryActivityOfMember < 0) {
        errors.primaryActivityOfMember = getMessage(props.messageCodes, '9112.text');
    }
    if (!values.describeProducts || (values.describeProducts && values.describeProducts.trim().length === 0)) {
        errors.describeProducts = getMessage(props.messageCodes, '9114.text');
    }

    // Validation for Consultant Details Form
    if (!values.consultingFirmName || (values.consultingFirmName && values.consultingFirmName.trim().length === 0)) {
        errors.consultingFirmName = getMessage(props.messageCodes, '9115.text');
    }
    if (!values.organizationNameYouRepresent || (values.organizationNameYouRepresent && values.organizationNameYouRepresent.trim().length === 0)) {
        errors.organizationNameYouRepresent = getMessage(props.messageCodes, '9528.text');
    }
    if (!values.bestDescribesConsultingFirm || (values.bestDescribesConsultingFirm && values.bestDescribesConsultingFirm.trim().length === 0)) {
        errors.bestDescribesConsultingFirm = getMessage(props.messageCodes, '9017.text');
    }
    if (!values.organizationNameConsultantDetails || (values.organizationNameConsultantDetails && values.organizationNameConsultantDetails.trim().length === 0)) {
        errors.organizationNameConsultantDetails = getMessage(props.messageCodes, '9113.text');
    }
    if (!values.organizationPrimarilyProduceProducts || (values.organizationPrimarilyProduceProducts && values.organizationPrimarilyProduceProducts.trim().length === 0)) {
        errors.organizationPrimarilyProduceProducts = getMessage(props.messageCodes, '9017.text');
    }
    if (!values.productSells || (values.productSells && values.productSells.trim().length === 0)) {
        errors.productSells = getMessage(props.messageCodes, '9120.text');
    }
    if (!values.businessActivity || (values.businessActivity && values.businessActivity.trim().length === 0)) {
        errors.businessActivity = getMessage(props.messageCodes, '9121.text');
    }
    if (!values.anyOrganizationPrimarilyProduceProducts || (values.anyOrganizationPrimarilyProduceProducts && values.anyOrganizationPrimarilyProduceProducts.trim().length === 0)) {
        errors.anyOrganizationPrimarilyProduceProducts = getMessage(props.messageCodes, '9017.text');
    }
    if (!values.organizationNameYouRepresenting || (values.organizationNameYouRepresenting && values.organizationNameYouRepresenting.trim().length === 0)) {
        errors.organizationNameYouRepresenting = getMessage(props.messageCodes, '9113.text');
    }

    if (!values.natureOfInterest || (values.natureOfInterest && values.natureOfInterest.trim().length === 0)) {
        errors.natureOfInterest = getMessage(props.messageCodes, '9124.text');
    }
    // Validation for Consumer Details form
    if (!values.consumerAdvocacyGroupBelong || (values.consumerAdvocacyGroupBelong && values.consumerAdvocacyGroupBelong.trim().length === 0)) {
        errors.consumerAdvocacyGroupBelong = getMessage(props.messageCodes, '9017.text');
    }
    if (!values.consumerAdvocacyGroupName || (values.consumerAdvocacyGroupName && values.consumerAdvocacyGroupName.trim().length === 0)) {
        errors.consumerAdvocacyGroupName = getMessage(props.messageCodes, '9126.text');
    }
    if (!values.natureOfInterestConsumerDetails || (values.natureOfInterestConsumerDetails && values.natureOfInterestConsumerDetails.trim().length === 0)) {
        errors.natureOfInterestConsumerDetails = getMessage(props.messageCodes, '9124.text');
    }

    return errors;
}
export const saveRecords = (values) => {
    // Hold All submit Payloads.
    submitPayloads = Object.assign({}, values);
    if (self.props.initialValues && ((self.state.officerTitleName == OfficerTitleEnum.StaffManager || self.state.officerTitleName == OfficerTitleEnum.AdministrativeAssistant) || self.props.initialValues.ParentCommitteeId == 0)) {
        confirmMainCommittee();
    }
    else {
        IsProducer = false;
        IsExists = false;
        let model = { committeeId: self.props.initialValues.CommitteeId, memberId: self.state.renderSelectedValueFromSearchList.MemberId }
        self.props.checkMemberExistsInParentCommittee(model, (response) => {
            IsProducer = response.IsProducer
            IsExists = response.IsExists;
            formattedCommittee = '';
            response.CommitteeList.map(item => formattedCommittee += item.FormattedCommittee + ', ')
            if (formattedCommittee !== '') {
                formattedCommittee = replaceLast(formattedCommittee, ', ', ' ');
            }
            if (!response.IsExists) {
                if (self.state.classificationSelectedValue == 3 && !IsProducer && self.props.initialValues.LevelId === 2) {
                    self.setState({ popupContent: formattedCommittee, showMainCommitteePopup: false, showMainCommitteeProducerPopup: true, mainCommitteeMessage: '9067.text' });
                } else {
                    self.setState({ popupContent: formattedCommittee, showMainCommitteePopup: true });
                }
            }
            else {
                confirmMainCommittee();
            }
        });
    }
}
const replaceLast = (x, y, z) => {
    let a = x.split("");
    a[x.lastIndexOf(y)] = z;
    return a.join("");
}
export const closeMainCommitteePopup = () => {
    self.setState({ popupContent: '', showMainCommitteePopup: false });
}
export const confirmMainCommittee = () => {
    if (self.state.classificationSelectedValue == 3 && !IsProducer && self.props.initialValues.LevelId === 2 && IsExists && (self.state.officerTitleName == OfficerTitleEnum.StaffManager || self.state.officerTitleName == OfficerTitleEnum.AdministrativeAssistant)) {
        self.setState({ popupContent: formattedCommittee, showMainCommitteePopup: false, showMainCommitteeProducerPopup: true, mainCommitteeMessage: '9053.text' });
    }
    else {
        SaveMemberCommittee();
    }

}
export const closeMainCommitteeProducer = () => {
    self.setState({ popupContent: '', showMainCommitteePopup: false, showMainCommitteeProducerPopup: false });
}
export const confirmMainCommitteeProducer = () => {
    SaveMemberCommittee();
}
export const handleClickOk = (e) => {
    self.setState({ isShowAdministrativeAssistantPopup: false, committeeDesignationValidation: '' });
}

export const confirmAdministrativeAssistantOrStaffManagerPopup = (event, value) => {
    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });

    // Updating Member Committee Basic Details
    SaveMemberCommittee(updatedDetailsForupdateMemberCommitteeBasicDetails);
}
export const SaveMemberCommittee = () => {
    let membershipTypeName = null;
    const { isPrimaryActivityApplicable } = self.state;
    let record = self.state.searchResultsData.find(x => x.AccountNumber === self.state.renderSelectedValueFromSearchList.AccountNumber);
    if (record) {
        let membership = self.props.membershipTypeList.find(x => x.value === record.MembershipTypeId);
        if (membership) {
            membershipTypeName = membership.text;
        }
    }

    const { initialValues: { CommitteeDesignation } } = self.props
    let CommitteeDesignations = CommitteeDesignation;
    // Get Details of primary Activty.
    if (isPrimaryActivityApplicable) {
        primaryDetails = committeeAndClassificationDetails(submitPayloads);
    }
    else {
        if (primaryDetails != null) {
            primaryDetails.committeememberclassification = null;
        }
    }

    if ((self.state.officerTitleName == OfficerTitleEnum.StaffManager || self.state.officerTitleName == OfficerTitleEnum.AdministrativeAssistant) && CommitteeDesignations.indexOf('.') > -1 && checkValue == 0) {
        checkValue = 1;
        self.setState({
            isShowAdministrativeAssistantOrStaffManagerPopup: true,
            committeeName: CommitteeDesignations && CommitteeDesignations.split('.')[0]
        });
    }
    else {
        self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });

        self.props.saveCommitteeMember({
            Committeeid: self.props.initialValues.CommitteeId, AccountNumber: self.state.renderSelectedValueFromSearchList.AccountNumber,
            DateJoined: self.state.dateJoined, ClassificationTypeId: self.state.classificationSelectedValue,
            Vote: self.state.voteStatus, NoVoteReason: self.state.noVoteReasonSelectedValue,
            VoteDate: self.state.voteDate, OfficerTitleId: self.state.officerTitleSelectedValue, OfficerAppointedDate: self.state.officerAppointedDate,
            ClassificationDate: self.state.classificationAssignDate, UpdateReason: self.state.UpdateReason, MembershipTypeName: membershipTypeName,
            committeedetails: primaryDetails
        }, (response) => {
            if (response !== undefined && response.message === 3025) {
                self.setState({
                    isShowAdministrativeAssistantPopup: true,
                    committeeDesignationValidation: response.content
                });
            } else {
                self.props.closeModal();
                closeMainCommitteeProducer();
                self.props.refreshCommitteeDetails();
            }
        });
    }
}

export const cancelAdministrativeAssistantOrStaffManagerPopup = () => {
    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });
    checkValue = 0;
}


export const getPrimaryActivityList = (customMembershipTypeId) => {
    self.props.getPrimaryActivityList(customMembershipTypeId, (response) => {
        const primaryActivityDropdown = DropdownFormatting(response, 'primaryActivityDropdown');
        self.setState({ masterPrimaryActivityDropdown: primaryActivityDropdown }, () => {
            filterPrimaryActivitiesOnOrgAssociation(self.state.isOrgAssociated, customMembershipTypeId);
        });

        if (response && response.length > 0) {
            self.setState({ isSaveButtonDisableForSubAndSectionCommitteePage: false });
        }
    });
}

export const onChangeCommitteeDetails = (event, value, preValue, name) => {
    if (name !== 'subCommittee') {
        self.setState({ [name]: value }, () => {
            if (value === -2) {
                self.setState({ subCommittee: [] }, () => {
                    self.props.change('subCommittee', self.state.subCommittee);
                });
            };
        });
    }
    if (name === 'primaryActivityOfMember') {
        self.setState({ [name]: value }, () => {
            self.props.change('primaryActivityOfMember', value);
        })
    }
    // Unused code
    // if (name === 'mainCommittee') {
    //     if (value > 0) {
    //         const committeeId = value;
    //         self.setState({ subCommittee: [] }, () => {
    //             self.props.change('subCommittee', self.state.subCommittee);
    //         });
    //         self.props.getSubCommitteesList(committeeId, (response) => {
    //             const subCommitteeDropdown = DropdownFormatting(response, 'subCommitteeDropdown');
    //             self.setState({ subCommitteeDropdown });
    //         });
    //     }
    // } else if (name === 'subCommittee') {
    //     const { subCommitteeDropdown } = self.state;
    //     let selectedCommittees = [];
    //     let previousSelectedCommittees = [];
    //     let itemAdded = [];
    //     selectedCommittees = value;
    //     previousSelectedCommittees = self.state.subCommittee;

    //     let removedCommitteeId = previousSelectedCommittees.filter((item) => !selectedCommittees.some((item2) => item2 == item));
    //     if (removedCommitteeId && removedCommitteeId.length > 0) {
    //         removedCommitteeId.map(((removedCommitteeIdItem) => {
    //             const removedCommitteeObj = find(subCommitteeDropdown, { SubCommitteeId: removedCommitteeIdItem });
    //             const filteredCommitteeIdArray = [];
    //             forEach(selectedCommittees, (subId) => {
    //                 const subCommitteeObj = find(subCommitteeDropdown, { SubCommitteeId: subId });
    //                 if (subCommitteeObj.CommitteeDesignation.indexOf(removedCommitteeObj.CommitteeDesignation) !== 0) {
    //                     filteredCommitteeIdArray.push(subCommitteeObj.SubCommitteeId);
    //                 }
    //             });

    //             self.setState({ subCommittee: filteredCommitteeIdArray }, () => {
    //                 self.props.change('subCommittee', filteredCommitteeIdArray);
    //             });
    //         }));

    //     } else {
    //         forEach(selectedCommittees, (subId) => {
    //             const subCommittee = find(subCommitteeDropdown, { SubCommitteeId: subId });
    //             const { CommitteeDesignation } = subCommittee;
    //             let splitDesgination = CommitteeDesignation.split('.');
    //             let checkedCommittee = splitDesgination[0]; // main committee
    //             for (let i = 1; i < splitDesgination.length; i++) {
    //                 checkedCommittee += "." + splitDesgination[i];
    //                 let autoSelectedCommittee = find(subCommitteeDropdown, { CommitteeDesignation: checkedCommittee });
    //                 if (!isEmpty(autoSelectedCommittee)) {
    //                     itemAdded.push(autoSelectedCommittee.SubCommitteeId);
    //                 }
    //             }
    //         });
    //         itemAdded = uniq(itemAdded);
    //         self.setState({ subCommittee: itemAdded }, () => {
    //             self.props.change('subCommittee', itemAdded);
    //         });
    //     }

    // }
}

const setPrimaryActivityForStudentMember = () => {
    self.setState({
        primaryActivityOfMember: primaryActivityModelConstant.other,
        natureOfInterest: getMessage(self.props.messageCodes, '9185.text')
    }, () => {
        self.props.change('primaryActivityOfMember', self.state.primaryActivityOfMember);
        self.props.change('natureOfInterest', self.state.natureOfInterest);
    })
}


export const filterPrimaryActivitiesOnOrgAssociation = (isOrgAssociated, customMembershipTypeId) => {
    self.setState({ primaryActivityOfMember: -1 }, () => {
        self.props.change('primaryActivityOfMember', -1);
    })
    let primaryActivityDropdown = []
    if (isOrgAssociated) {
        primaryActivityDropdown = self.state.masterPrimaryActivityDropdown
    } else {
        primaryActivityDropdown = self.state.masterPrimaryActivityDropdown.filter(x => (x.CommitteePrimaryActivityId === primaryActivityModelConstant.consumer || x.CommitteePrimaryActivityId === primaryActivityModelConstant.other || x.CommitteePrimaryActivityId === primaryActivityModelConstant.consultant || x.key === -1))
    }
    self.setState({ primaryActivityDropdown })
    if (self.state.flagFeature.StudentCommitteeManagement && customMembershipTypeId === studentMembershipId) {
        setPrimaryActivityForStudentMember();
    }
}

export const onChangeCommonForm = (event, value, preValue, name) => {
    self.setState({ [name]: value });
};

// Prepare Committee Data
export const committeeAndClassificationDetails = (postData) => {
    const sameActivityIds = [
        primaryActivityModelConstant.manufacturerOfProductService,
        primaryActivityModelConstant.salesDistributorOfMaterialProductService,
        primaryActivityModelConstant.userPurchaserOfProductService,
        primaryActivityModelConstant.testingOfProductService
    ];
    let committeememberclassification = {};

    const primaryActivityOfMember = postData.primaryActivityOfMember;

    if (primaryActivityOfMember > 0) {
        if (sameActivityIds.indexOf(primaryActivityOfMember) >= 0) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                organizationdescription: postData.describeProducts ? postData.describeProducts : null
            };
        } else if (primaryActivityOfMember === primaryActivityModelConstant.consultant || primaryActivityOfMember === primaryActivityModelConstant.consultingFirm) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                multipleorganization: postData.bestDescribesConsultingFirm === "2",
                singleorganization: postData.bestDescribesConsultingFirm === "1",
                nameoforganization: postData.bestDescribesConsultingFirm === "2" ? postData.organizationNameYouRepresenting : postData.organizationNameYouRepresent,
                website: postData.organizationWebsiteConsultantDetails ? postData.organizationWebsiteConsultantDetails : null,
                primarilyconsultant: postData.organizationPrimarilyProduceProducts === "2",
                businessactivity: postData.bestDescribesConsultingFirm === "1"
                    ? (postData.organizationPrimarilyProduceProducts === "2" ? postData.productSells : postData.businessActivity)
                    : (postData.anyOrganizationPrimarilyProduceProducts === "2" ? postData.productSells : postData.businessActivity)
            };
        } else if (primaryActivityOfMember === primaryActivityModelConstant.consumer || primaryActivityOfMember == primaryActivityModelConstant.consumerAdvocacyGroup) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                facilityorganization: postData.consumerAdvocacyGroupName ? postData.consumerAdvocacyGroupName : null,
                advocacyemail: postData.advocacyGroupEmail ? postData.advocacyGroupEmail : null,
                organizationdescription: postData.natureOfInterestConsumerDetails ? postData.natureOfInterestConsumerDetails : postData.natureOfInterest ? postData.natureOfInterest : null
            };
        } else if (primaryActivityOfMember === primaryActivityModelConstant.governmentAgency) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                organizationdescription: postData.natureOfInterest ? postData.natureOfInterest : null
            };
        } else if (primaryActivityOfMember === primaryActivityModelConstant.academia) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                organizationdescription: postData.natureOfInterest ? postData.natureOfInterest : null
            };
        } else if (primaryActivityOfMember === primaryActivityModelConstant.other) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                organizationdescription: postData.natureOfInterest ? postData.natureOfInterest : null
            };
        }
        const committeedetails = {
            CommitteeIds: [postData.CommitteeId],
            subcommitteeid: isEmpty(postData.subCommitteeIds) ? [] : postData.subCommitteeIds,
            committeememberclassification
        };
        return committeedetails;
    }

    return null;
};
